import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { GoHeart, GoHeartFill } from "react-icons/go";
import { useNavigate, useLocation } from "react-router-dom";

interface ScreenWrapperProps {
  title: string;
  titleSize?: string;
  children?: React.ReactNode;
  isHeartVisible?: boolean;
  isLiked?: boolean;
  handleLike?: () => void;
}

const ScreenWrapper: React.FC<ScreenWrapperProps> = ({
  title,
  titleSize,
  children,
  isHeartVisible = false,
  isLiked,
  handleLike,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    // Check if the current path is a restaurant page
    if (location.pathname.startsWith("/restaurant")) {
      navigate("/home"); // Navigate to the home page
    } else {
      navigate(-1); // Go back in history
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen overflow-hidden">
      {/* banner container */}
      <div className="container flex flex-col justify-center items-center w-full">
        {/* form container */}
        <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 flex flex-col justify-center items-center gap-5 p-5">
          <div className="flex items-center w-full my-6" onClick={handleBackClick}>
            <MdArrowBackIosNew
              size={26}
              className="text-primary justify-start text-2xl mr-4 cursor-pointer"
            />
            <h1
              className={`${
                titleSize ?? "text-2xl"
              } font-bold text-center font-poppins w-5/6`}
            >
              {title}
            </h1>
            {isHeartVisible && handleLike && (
              <div className="ml-4 justify-end">
                {isLiked ? (
                  <GoHeartFill
                    onClick={handleLike}
                    size={25}
                    className="text-[#C12121] cursor-pointer border-[#ECECEA] my-1"
                  />
                ) : (
                  <GoHeart
                    onClick={handleLike}
                    size={25}
                    className="text-[#B1B3B6] cursor-pointer hover:text-[#C12121] my-1"
                  />
                )}
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ScreenWrapper;
